.locationModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}

.modalContent {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    width: 90%;
    max-width: 600px;
    position: relative;
    z-index: 1002;
    text-align: center;

    h2 {
        margin-bottom: 2rem;
        color: #333;
        font-size: 1.5rem;
    }
}

.deliveryTypes {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
}

.typeButton {
    padding: 0.5rem 2rem;
    border: none;
    border-radius: 25px;
    background: #f0f0f0;
    cursor: pointer;
    transition: all 0.3s ease;

    &.active {
        background: #039aa6;
        color: white;
    }
}

.citiesGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 1rem;
    margin-bottom: 2rem;
}

.cityButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0rem 2rem 0rem;
    border: 1px solid #eee;
    border-radius: 8px;
    background: white;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    &.selected {
        border-color: #039aa6;
        background: #fff5f500;
    }

    .icon {
        // width: 24px;
        height: 24px;
        object-fit: cover;
        margin-bottom: 8px;
    }
}

.areaSelect {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 2rem;
    font-size: 1rem;
}

.selectButton {
    background: #039aa6;
    color: white;
    border: none;
    padding: 1rem 3rem;
    border-radius: 50px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: all 0.3s ease;

    &:disabled {
        background: #ccc;
        cursor: not-allowed;
    }

    &:not(:disabled):hover {
        background: darken(#039aa6, 10%);
    }
}

@media (max-width: 768px) {
    .modalContent {
        padding: 1.5rem;
        width: 95%;
    }

    .citiesGrid {
        grid-template-columns: repeat(2, 1fr);
    }
}
