.BounceLoader {
  display: flex;
  flex-direction: column;
  align-items: center;

  .topMsg, .bottomMsg {
    font-size: 0.8rem;
  }

  .shapeContainer {
    width: 60px;
    height: 60px;
    margin: 10px 10px 0;
    position: relative;
    &:before, &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(128, 128, 128, 0.5);
      border-radius: 50%;
    }
    &:before {
      animation: scaleDown 1.5s ease-in-out infinite;
    }
    &:after {
      animation: scaleUp 1.5s ease-in-out infinite;
    }
  }
}

@keyframes scaleDown {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes scaleUp {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
