// @import url('https://fonts.googleapis.com/css?family=Chivo:300,400,700,900');
$grey: #808080;

:root {
  --primary-color: #c20a05;
  --secondary-color: #312d2d;
}

$primary-color: var(--primary-color);
$secondary-color: var(--secondary-color);

$font: "Cairo", sans-serif;

@mixin transform($property) {
  -webkit-transform: $property;
  -ms-transform: $property;
  transform: $property;
}

@mixin transition($time) {
  -webkit-transition: all $time ease-in-out;
  -ms-transition: all $time ease-in-out;
  -o-transition: all $time ease-in-out;
  transition: all $time ease-in-out;
}
