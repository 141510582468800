#notfound {
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    @media (max-width: 991px) {
      height: 90vh;
    }

    .contentWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 991px) {
        flex-direction: column-reverse;
        gap: 20px;
        height: 90%;
      }

      .serach-btn {
        position: absolute;
        top: 0;
        left: 15%;
        z-index: 1;

        @media (max-width: 991px) {
          top: -17%;
          left: -10%;
          z-index: 0;
        }

        @media (max-width: 599px) {
          top: -25%;
          left: -20%;
        }

        @media (max-width: 360px) {
          top: -30%;
        }

        @media (min-width: 1024px) {
          top: 12%;
          left: -5%;
        }

        @media (min-width: 1366px) {
          top: -32%;
          left: 2%;
        }

        @media (min-width: 1440px) {
          top: -5%;
          left: 4%;
        }

        @media (min-width: 1920px) {
          top: 3%;
          left: 15%;
        }

        @media (min-width: 2048px) {
          top: 8%;
          left: 17%;
        }
      }

      .details {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 24px;
        width: 48%;

        @media (max-width: 991px) {
          width: 90%;
          gap: 12px;
        }

        @media (max-width: 600px) {
          width: 100%;
        }

        h3 {
          color: #101828;
          font-size: 60px;
          line-height: 72px;
          font-weight: 600;
          margin: 0;

          @media (min-width: 1024px) {
            font-size: 55px;
          }

          @media (max-width: 991px) {
            font-size: 50px;
            line-height: 58px;
            font-weight: 700;
            letter-spacing: -0.02em;
          }

          @media (max-width: 360px) {
            font-size: 36px;
            line-height: 41px;
          }
        }

        p {
          font-size: 20px;
          line-height: 30px;
          color: #475467;

          @media (max-width: 991px) {
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 12px;
          }

          @media (max-width: 360px) {
            line-height: 20px;
          }
        }

        a {
          background-color: #ec612a;
          color: #fff;
          border: 1px solid #ec612a;
          padding: 14px 67px;
          border-radius: 12px;
          width: fit-content;
          font-size: 18px;
          font-weight: 500;
          transition: all .25s ease-in-out;

          &:hover {
            background-color: transparent;
            border: 1px solid #131313;
            color: #131313;
          }
        }
      }

      .main-slide {
        width: 70%;
        margin: 0;
        position: absolute;
        right: 0;

        @media (max-width: 991px) {
          height: 100%;
          width: 100%;
          position: relative;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;

          @media (max-width: 991px) {
            object-position: 65%;
            overflow: visible;
          }
        }
      }
    }
  }
}