// @import "../Themes/Levis/VariablesScss/variables";

.MyAccount {
  display: flex;
  flex-direction: column;
  font-family: "Gotham", "Montserrat", "sans-serif";
  .sectionHeaderWrpr {
    .dataRow {
      display: flex;
      .leftColumn {
        width: 290px;
        margin-right: 30px;
        padding: 10px;
      }
      .rightColumn {
        padding: 10px;
        flex-grow: 1;
        .spinnerContainer {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  @media screen and (max-width: 960px) {
    .sectionHeaderWrpr .dataRow {
      flex-direction: column;
      .leftColumn {
        width: 100%;
      }
    }
  }
}
