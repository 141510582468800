.login {
    background: #f9f9f9;
    padding-top: 100px;
    padding-bottom: 100px;
    .login-cont {
        background: #fff;
        border-radius: 6px;
        padding: 50px;
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
        @media (max-width: 800px) {
            padding: 20px !important;
        }
        .form {
            margin-top: 50px;
            .input-group {margin-bottom: 30px !important;width: 100% !important;}
            .form-check {
                margin-left: 6px;
                margin-top: 20px;
                margin-bottom: 20px;
            }
            .btn-submit-recover {
                color: #fff;
                // width: 300px;
                // height: 45px;
            }
        }
        .register {
            text-align: center;
            margin-top: -74px;
            @media (max-width: 800px) {
                margin-top: 20px !important;
            }
            img {
                margin-bottom: 10px;
            }
            a {
                color: var(--primary-color);
                &:hover {color: #1b1b1b;}
            }
        }
    }
}