// global import
@import "Variable";
// Global Style

body {
    overflow-x: hidden;
    font-family: $font;
}

body[data-scroll-mobile='false'] {
  @media screen and (max-width: 768px) {
    overflow: hidden;
  }
}

body[data-scroll='false'] {
  overflow: hidden;
}

.App {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100dvh;

  #mainLayout {
    position: relative;
    flex: 1;
  }
}

.menuFiller {
  height: 6.011875rem;

  @media screen and (min-width: 1024px) {
    height: 7.761875rem;
  }
}

button {
    outline: none;
    &:focus,
    &:active,
    &:hover {
        outline: none !important;
    }
    &:hover {
        cursor: pointer;
    }
}

input {
    outline: none !important;
    &:focus,
    &:active,
    &:hover {
        outline: none !important;
    }
}

a {
    text-decoration: none !important;
    color: inherit;
    outline: none !important;
    &:hover,
    &:link,
    &:visited,
    &:focus,
    &:active {
        text-decoration: none;
        color: inherit;
        outline: none !important;
    }
}

.fixed {
    position: fixed;
    width: 100%;
    z-index: 99;
    top: 0;
    left: 0;
}

.ant-tabs {
    height: auto !important;
}

.nav-tabs {
    margin-bottom: 50px;
    border: none;
    .nav-link {
        border: none;
    }
}

.df-jcsb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.relative {
    position: relative;
}

// main Class
.color {
    color: $primary-color !important
}

.bg {
    background: $primary-color !important
}

.bold {
    font-weight: 600
}

.trans {
    transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -webkit-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
}

// to prevent blue screen select
img {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

html, body {
    max-width: 100%;
    font-family: 'Cairo', sans-serif !important;
  }
  
  .btn-red {
    width: 110px;
    padding:  5px 10px !important;
    box-shadow: -1px -1px 2.5px black !important;
    background-color: var(--primary-color) !important;
    color: white !important;
  }
  
  .btn-regular {
    width: 120px;
    border-radius: 0 !important;
    border: 1px solid black !important;
    color: var(--primary-color) !important;
    font-weight: 500 !important;
    padding: 10px 8px !important;
    &:hover {
      background-color: var(--primary-color);
      color: white !important;
    }
  }
  
  .shop-now-black{
    width: 130px;
    background-color: black !important;
    border-radius: 0 !important;
    border: 1px solid black !important;
    color: white !important;
    font-weight: 500 !important;
    padding: 8px !important;
    &:hover {
      background-color: var(--primary-color);
      color: white !important;
    }
  }
  
  .image-gallery {
    padding: 0 50px;
    .image-gallery-thumbnails-container {
      display: inline-flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      a {
        width: 25%;
      }
    }
  }
  
  @media only screen and (min-width: 1200px) {
    body, html {
      // max-width: 1550px;
      margin: auto;
    }
  }